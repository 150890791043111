@mixin scaleAndClose($start, $middle, $end) {
  @keyframes scaleAndClose {
    0% {
      opacity: 0;
      transform: scale($start);
    }
    50% {
      opacity: 1;
      transform: scale($middle);
    }
    100% {
      opacity: 1;
      transform: scale($end);
    }
  }
}

@mixin fadeInDown($from, $to) {
  @keyframes fadeInDown {
    from {
      transform: translate3d(0, $from, 0);
      opacity: 0;
    }

    to {
      transform: translate3d(0, $to, 0);
      opacity: 1;
    }
  }
}

@mixin fadeInUp($from, $to) {
  @keyframes fadeInUp {
    from {
      transform: translate3d(0, $from, 0);
      opacity: 0;
    }

    to {
      transform: translate3d(0, $to, 0);
      opacity: 1;
    }
  }
}
