@import "../../../shared/sass/abstracts/mixins";

.post-ad__footer {
  margin-top: 2.4rem;

  display: flex;
  align-items: center;
}

.post-ad__btn {
  // Step Form
  padding: 1.7rem 2.5rem;
  font-size: 1.5rem;
  font-weight: 600;

  &:not(:last-child) {
    margin-right: 1.2rem;
  }

  &.next {
    @include button(var(--color-primary), #fff, #fff, var(--color-primary-2));
  }

  &.prev {
    @include button(var(--color-primary-2), #fff, #fff, var(--color-primary));
  }
}
