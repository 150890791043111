// ------------- FLEXBOX -------------
.u-d-flex {
  display: flex;
}

.u-aligns-center {
  align-items: center;
}

.u-justify-center {
  justify-content: center;
}

.u-justify-between {
  justify-content: space-between;
}

// ------------- CENTER -------------
.u-center-text {
  text-align: center;
}

// ------------- SPACE -------------
.u-margin-bottom-big {
  margin-bottom: 8rem;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem;
}

.u-margin-top-big {
  margin-top: 8rem;
}

.u-margin-top-medium {
  margin-top: 4rem;
}

.u-margin-top-small {
  margin-top: 1.5rem;
}

.u-margin-left-big {
  margin-left: 8rem;
}

.u-margin-left-medium {
  margin-left: 4rem;
}

.u-margin-left-small {
  margin-left: 1.5rem;
}

.u-margin-right-big {
  margin-right: 8rem;
}

.u-margin-right-medium {
  margin-right: 4rem;
}

.u-margin-right-small {
  margin-right: 1.5rem;
}

// ------------- SIZE -------------
.u-width-100 {
  width: 100%;
}
