.loading-spinner {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
  }

  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  &__icon {
    width: 10rem;
    height: 10rem;
    display: inline-block;
    padding: 0;

    span {
      position: absolute;
      display: inline-block;
      width: 10rem;
      height: 10rem;
      border-radius: 100%;
      background: #5830e0;
      -webkit-animation: preloader-fx 1.6s linear infinite;
      animation: preloader-fx 1.6s linear infinite;

      &:last-child {
        animation-delay: -0.8s;
        -webkit-animation-delay: -0.8s;
      }
    }
  }
}

@keyframes preloader-fx {
  0% {
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}

/* Type 2 */
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 7rem;
  height: 7rem;
  margin: 1px;
  border-radius: 50%;
  border: 7px solid var(--color-primary-2);
  border-color: var(--color-primary) transparent var(--color-primary)
    var(--color-primary);
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(52, 58, 64, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
