@import "../../sass/abstracts/mixins";

.modal-action {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-icon {
      color: var(--color-primary);
      font-size: 4.5rem;

      &__circle {
        padding: 1.2rem;
      }

      &--warning {
        @include circle(4.5rem, 4.5rem, var(--color-warning));
        color: var(--color-warning);
      }

      &--success {
        @include circle(4.5rem, 4.5rem, var(--color-green));
        border: 2px solid var(--color-green);
        color: var(--color-green) !important;
      }
    }

    span {
      font-size: 2.5rem;
      display: block;
      margin-top: 1.4rem;
      color: var(--font-color-1);
      font-weight: 400;
    }
  }

  &__content {
    font-size: 1.8rem;
    text-align: center;
    font-weight: 400;
    color: var(--color-grey-dark-2);
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
