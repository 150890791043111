.data-table {
  height: 60rem;
  padding: 2rem;
  width: 100%;

  * > p,
  div,
  span {
    font-size: 1.5rem;
    margin: 0 !important;
  }
}

.cell__image-group {
  display: flex;
  align-items: center;
}

.cell__image {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 2rem;
}

.cell__status {
  padding: 0.8rem;
  border-radius: 5px;

  &.active {
    background-color: var(--color-green-background);
    color: var(--color-green);
    border: 1px solid var(--color-green);
  }

  &.passive {
    background-color: var(--color-red-background);
    color: var(--color-red);
    border: 1px solid var(--color-red);
  }

  &.pending {
    background-color: var(--color-yellow-background);
    color: var(--color-yellow);
    border: 1px solid var(--color-yellow);
  }
}
