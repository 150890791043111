:root {
  --color-primary: #5830e0;
  --color-primary-2: #081828;

  --color-primary-admin: #6439ff;
  --color-primary-admin-1: #ece8ff;

  --color-green: #03a70c;
  --color-green-light: #15c0b0;
  --color-green-dark: #3fdd90;
  --color-green-background: rgba(0, 128, 0, 0.05);

  --color-active: #0167f3;

  --color-yellow: #fbaa26;
  --color-warning: #ffeb3b;
  --color-yellow-background: rgba(255, 217, 0, 0.05);

  --color-red: #d83148;
  --color-red-light: #f8d7da;
  --color-red-light-1: #f5c6cb;
  --color-red-background: rgba(255, 0, 0, 0.05);

  --font-color: #243238;
  --font-color-2: #888;

  --color-grey-light-1: #f9f9f9;
  --color-grey-light-2: #f4f2f2; // background color
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;

  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #fff3;

  --color-facebook: #3b5999;
  --color-google: #dd4b39;

  --color-border-dark: #3333331a;
  --color-border: #eee;
  --color-border-1: #dadada;

  --color-disabled: #666666;

  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
  --shadow-dark-slightly: 0px 1px 4px rgba(0, 0, 0, 0.12);
  --shadow-dark-slightly-1: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  --shadow-light-2: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

  --shadow-1: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  --height-header: 7.5rem;
  --height-header-admin: 6rem;
}
