@import "../../../shared/sass/abstracts/mixins";
@import "../../../shared/sass/base/animations";

:root {
  --height-preview-file: 9.5rem;
}

.room-chat__form-wrapper {
  border-top: 1px solid var(--color-border-dark);
}

.room-chat__form {
  padding: 1.5rem;

  display: flex;
  align-items: center;

  &-group {
    background-color: #f4f7ff;
    height: 5rem;
    width: 100%;
    border: 1px solid var(--color-border-dark);
    border-radius: 5px;
    display: flex;
    align-items: center;

    position: relative;
  }

  &-input {
    height: 100%;
    width: 100%;
    font-size: 1.4rem;
    padding: 1.4rem 0.6rem 1.4rem 1.4rem;
    outline: none;
    border: none;
    background-color: transparent;

    &__error {
      @include fadeInUp(30px, 0);
      position: absolute;
      top: -43px;
      left: 0;
      right: 0;

      font-size: 1.3rem;
      font-weight: 500;
      background-color: var(--color-red-light);
      border: 1px solid var(--color-red-light-1);
      color: var(--color-red);
      padding: 0.8rem 1.2rem;
      border-radius: 2px;
      transition: all 0.2s ease-in;
      animation: fadeIn 0.2s ease-in, fadeInUp 0.4s ease-in-out;
    }
  }

  &-icon {
    padding: 1.2rem;
    font-size: 1.6rem;
    color: var(--color-grey-dark-2);
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &-submit {
    height: 5rem;
    width: 5rem;
    padding: 0 1.4rem;
    margin-left: 1.4rem;
    border: none;
    background-color: var(--color-primary);
    border-radius: 5px;
    font-size: 1.6rem;
    color: #fff;
    outline: none;

    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
  }
}

.preview__file-list {
  @include scrollbarWithColorPrimary(0.3em, 0.6em);

  min-height: var(--height-preview-file);
  padding: 1.2rem;
  display: flex;
  align-items: center;
  width: 55rem;
  overflow-x: auto;
}

.preview__file-item {
  list-style: none;
  width: 14rem;
  margin: 1.2rem 0.8rem 0rem 1.4rem;

  position: relative;
}

.preview__file-icon__close {
  position: absolute;
  top: -7px;
  right: -10px;

  @include circle(
    1.2rem,
    1.2rem,
    var(--color-grey-light-2),
    var(--color-primary-2)
  );

  color: var(--color-grey-light-2);
  padding: 0.4rem 0.4rem;

  &:hover {
    background-color: var(--color-grey-light-2);
    border: 1px solid var(--color-primary-2);
    color: var(--color-primary-2);
  }
}

.preview__file {
  display: flex;
  align-items: center;
  border-radius: 5px;
  color: #fff;
  transition: all 0.2s ease-in;
  border: 1px solid var(--color-border);
  overflow: hidden;
  cursor: pointer;

  &--image {
    height: 10rem;
    width: 14rem;
  }

  &--file {
    background-color: var(--color-primary-2);
    padding: 1.2rem;

    &:hover {
      color: var(--color-primary-2);
      border: 1px solid var(--color-primary-2);
      background-color: var(--color-grey-light-2);
    }
  }

  &-icon {
    font-size: 2rem !important;
  }

  &-name {
    @include limitWidthFont(9rem);

    font-size: 1.4rem;
    margin-left: 0.8rem;
  }

  &-image {
    height: 100%;
    width: 100%;
    transition: transform linear 0.2s;
    object-fit: cover;

    &:hover {
      transform: scale(1.1);
    }
  }

  &-additional {
    border: 2px solid var(--color-border);
    border-style: dashed;
    padding: 1.2rem 2.2rem;
    margin-left: 1.2rem;
    height: calc(var(--height-preview-file) - 2rem);
    min-width: 8rem;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &-icon {
      font-size: 2rem;
      color: var(--color-grey-dark-2);
      border-radius: 5px;
    }
  }
}
