@import "../../sass/abstracts/mixins";

.swiper-slider {
  width: 100%;
  height: 400px;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 3px;
  overflow: hidden;

  &__image {
    flex: 0 0 18%;
    border-radius: 3px;
    object-fit: cover;
    transition: all 0.3s linear;
  }

  &__main-image {
    width: 500%;
    height: 100%;
    display: flex;
    position: relative;

    &--active {
      position: absolute;
      margin-left: -100%;
    }
  }

  &__sub-image {
    height: 120px;
    max-width: 100%;
    cursor: pointer;
    margin-right: 12px;
    display: flex;
    align-items: center;
    overflow: hidden;

    img {
      overflow: hidden;
      height: 70px;
      width: 100%;
    }

    &--active {
      transform: scale(1.05);
      margin: 12px 18px 0 0;
      overflow: hidden;
    }

    &--active {
      border: 1px solid var(--color-grey-dark-2);
    }
  }
}

.fade {
  animation: fade-in-keyframes 1s;
}

@keyframes fade-in-keyframes {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Button redirect*/
.swiper-slider__btn-redirect {
  &__icon {
    position: absolute;
    top: 85%;
    padding: 12px;
    cursor: pointer;
    color: var(--font-color);

    @include circle(1.5rem, 1.5rem, var(--color-grey-light-3), #fff);
    font-size: 1.4rem;
    color: var(--color-primary);
    padding: 0.8rem;

    &:hover {
      transform: scale(1.05);
    }

    &.next-slider {
      right: 70%;
    }

    &.prev-slider {
      right: 85%;
    }
  }
}
