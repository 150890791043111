@import "../../sass/abstracts/mixins";
@import "../../sass/base/animations";

.header {
  height: var(--height-header);

  display: flex;
  align-items: center;

  background-color: #fff;
  border-bottom: 1px solid var(--color-grey-light-3);
  box-shadow: var(--shadow-light);

  &__container {
    width: 100%;
    height: var(--height-header);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-logo {
    &__box {
      width: 19rem;
    }

    &__photo {
      width: 100%;
      object-fit: cover;
    }
  }

  &__nav {
    align-self: stretch;
    &-list {
      list-style: none;
      display: flex;
      align-items: center;
      height: 100%;
    }

    &-item {
      font-size: 1.5rem;
      margin-right: 2rem;
      height: 100%;
      overflow: hidden;
    }

    &-link {
      color: var(--color-primary-2);
      display: inline-block;
      font-weight: 500;
      height: inherit;
      padding: 2rem 1.5rem;
      position: relative;

      transition: all 0.3s ease-in;

      &:hover {
        color: var(--color-primary);
        text-decoration: none;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 94%;
        left: 0;
        background-color: var(--color-primary);
        height: 0.5rem;
        width: 0;
        border-radius: 3px;
        transition: width 0.3s ease-in;
        overflow: hidden;
      }
    }

    &-link--active {
      color: var(--color-primary);
    }

    &-item:hover &-link::after,
    &-link--active::after {
      width: 100%;
    }
  }

  &__action {
    &-list {
      list-style: none;
      display: flex;
      align-items: center;
    }

    &-item {
      display: flex;
      align-items: center;
      padding: 0.6rem 2rem;
      cursor: pointer;

      &__name {
        @include limitWidthFont(15rem);
        margin-left: 1.2rem;
      }

      a,
      span {
        font-size: 1.3rem;
        font-weight: 500;
        color: var(--color-primary-2);
        text-decoration: none;

        &:hover {
          color: var(--color-primary);
        }
      }
    }

    &-btn {
      color: #fff !important;
      padding: 1.5rem 2.4rem !important;
      font-weight: 500;
    }
  }

  &__icon {
    font-size: 1.5rem;
    margin-right: 0.8rem;

    &.circle {
      @include circle(1.6rem, 1.6rem, var(--color-border), #fff);
      padding: 1rem;
    }
  }
}

/* MENU FOR USER */
.header__action-item--have-menu {
  position: relative;

  &:hover .header__menu-user-popper {
    overflow: hidden;
    display: flex !important;
  }
}

.header__menu-user__icon {
  margin-right: 1.2rem;
}

.header__menu-user-popper {
  position: absolute;
  top: 100%;
  right: 0;
  transform-origin: top right;
  animation: fadeIn 0.2s ease-in, scaleAndClose 0.25s ease-in-out forwards;
  @include scaleAndClose(0.5, 1.1, 1);
  transition: all 0.25s;

  display: none !important;
}

.header-logo__photo {
  height: var(--height-header);
  width: auto; 
}

.header__menu-user {
  &-list {
    list-style: none;
    width: 100%;
  }

  &-item {
    padding: 1.2rem;
    font-size: 1.4rem;
    width: 100%;
    margin: 0 !important;

    display: block;

    transition: background-color 0.15s;

    &:hover {
      background-color: var(--color-grey-light-2);
      color: var(--color-primary);
      cursor: pointer;
    }

    &:hover span {
      color: var(--color-primary);
    }
  }
}

/* MENU FOR USER */
