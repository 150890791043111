@import "../../../shared/sass/abstracts/mixins";

.dashboard-user {
}

/* CARD */
.dashboard-user__card {
  border-radius: 5px;
  border: 1px solid var(--color-border-dark);
  background-color: #fff;
  padding: 3.5rem 3rem;

  display: flex;

  /* ICON */
  &-icon__group {
    @include circle(6.5rem, 6.5rem, none, #fff);
  }

  &-icon {
    font-size: 2.5rem;
    color: var(--color-green-dark);
  }
  /* ICON */

  &-detail {
    margin-left: 1.4rem;

    h3 {
      font-size: 2.3rem;
    }

    span {
      font-size: 1.4rem;
    }
  }
}
/* CARD */

/* ACTIVITY LOG */
.dashboard-user__activity {
  &-list {
    list-style: none;
  }

  &-item {
    display: flex;
    align-items: center;
    padding: 1.6rem 2.6rem;
    transition: background-color 0.2 ease-in;

    &:hover {
      background-color: var(--color-grey-light-3);
      cursor: pointer;
    }
  }

  &-icon {
    @include circle(1.5rem, 1.5rem, var(--color-primary), #fff);
    padding: 0.8rem;
    color: var(--color-primary);
  }

  &-img {
    height: 6rem;
    width: 6rem;
    object-fit: cover;
  }

  &-detail {
    margin-left: 1.3rem;

    h4 {
      font-size: 1.4rem;
      font-weight: 500;
    }

    span {
      display: block;
      font-size: 1.3rem;
      color: var(--color-grey-dark-2);
    }
  }
}
/* ACTIVITY LOG */
