.search-navbar {
  display: flex;
  align-items: center;
  padding: 3rem 4.5rem;
  background-color: rgba(255, 255, 255, 0.2);
  width: fit-content;
  border-radius: 3px;
  margin: 4rem auto;

  &__group {
    display: flex;
    align-items: center;
    margin-right: 2rem;

    position: relative;
  }

  &__input {
    font-size: 1.4rem;
    height: 50px;
    padding: 0 4.5rem 0 2.5rem;
    border-radius: 3px;
    border: none;
    outline: none;
    background-color: #fff;
    width: 250px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &__label {
    position: absolute;
    right: 1.5rem;
  }

  &__icon {
    top: 50%;
    height: 1.5rem;
    color: var(--color-grey-dark-2);
    font-weight: 300;
  }

  &__btn {
    height: 50px;
    padding: 0rem 3rem;
    font-weight: 600;

    span {
      font-size: 1.5rem;
      margin-left: 1rem;
    }
  }
}
