.formCategorization {
  width: 100%;
  background-color: var(--color-grey-light-2);
  padding: 1.8rem 1.2rem;

  position: relative;

  &__icon {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 2rem;
    padding: 1.2rem;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  &__iconPlus {
    font-size: 1.6rem !important;
    padding: 0.8rem;
    margin-left: 0.6rem;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  &__input {
    min-height: 4rem;
    width: 80%;
  }
}

.title {
  font-size: 1.4rem;
  width: 14rem;
  margin: 0;
}
