.admin-home {
  &__widgets,
  &__charts {
    display: flex;
    padding: 2rem;
    gap: 20px;
  }

  &__charts {
    padding: 5px 20px;
  }
}
