@import "../../../shared/sass/base/animations";
@import "../../../shared/sass/abstracts/mixins";

.multiple__select-form {
  position: relative;
}

.multiple__select-select {
  display: flex;
  align-items: center;
  width: 100%;
  line-break: auto;
  flex-wrap: wrap;
  background-color: #fff;
  z-index: 10;

  &:hover {
    cursor: pointer;
  }

  &__item {
    background-color: var(--color-grey-light-2);
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 0.6rem 0.8rem;
    border-radius: 10px;
    margin-bottom: 0.6rem;
    z-index: 99;

    &:not(:last-child) {
      margin-right: 1.2rem;
    }
  }

  &__icon {
    @include circle(1rem, 1rem, var(--color-grey-dark-2));
    padding: 0.2rem;
    margin-left: 0.8rem;

    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
}

.multiple__select-dropdown {
  @include fadeInDown(-50px, 0);
  margin-top: 1.2rem;

  border: 1px solid var(--color-grey-light-3);
  transform-origin: top center;
  border-radius: 3px;
  animation: fadeInDown linear 0.2s, fadeIn 0.25s ease-in;
  background-color: #fff;
  display: none;

  &.active {
    display: block;
  }

  &__item {
    height: 3.8rem;
    padding: 0.8rem 1.2rem;
    transition: all 0.1s linear;
    background-color: transparent;
    z-index: 99;

    display: flex;
    align-items: center;

    &.active {
      .multiple__select-dropdown__text {
        color: var(--color-primary);
        font-weight: 600;
      }

      .multiple__select-dropdown__icon {
        visibility: visible;
      }
    }

    &:hover {
      background-color: #eee;
      cursor: pointer;
    }
  }

  &__icon {
    color: var(--color-primary);
    font-size: 1.6rem;
    margin-right: 0.8rem;
    visibility: hidden;
    transition: all ease-in 0.1s;
    animation: fadeIn 0.25s ease-in;
  }

  &__text {
    font-size: 1.4rem;
  }
}

.multiple__select--add-more {
  padding: 0.8rem 1.4rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-primary-2);
  border-top: 1px solid var(--color-border);

  display: flex;
  align-items: center;
  cursor: pointer;

  &__text {
    margin-left: 1.2rem;
  }

  &__icon {
    padding: 1rem !important;
    border: 1px solid var(--color-border);
    border-radius: 5px;
    font-size: 1.6rem;
    margin-left: 0.8rem;
    min-width: fit-content;
    background-color: transparent;

    &:hover {
      background-color: var(--color-grey-light-2);
      cursor: pointer;
    }
  }

  &:hover {
    opacity: 0.8;
  }
}

.multiple__select__form {
  &-group {
    margin: 0 !important;
  }

  &-input {
    min-height: unset;
    height: 3.8rem;
    transform: translateY(-4px);
  }
}

.multiple__select-option {
  &__title {
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0.8rem 1.2rem;
    background-color: var(--color-grey-light-2);
  }

  &__icon {
    @include circle(1.2rem, 1.2rem, var(--color-grey-light-2));
    padding: 0.6rem;

    &:hover {
      background-color: var(--color-grey-dark-2);
      color: #fff;
    }
  }
}
