.widget {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 2rem;
  border-radius: 10px;
  height: fit-content;

  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: var(--shadow-light-2);
  
  &-left,
  &-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-icon {
    font-size: 2.8rem !important;
    transform: translateY(-1px);

    &__main {
      padding: 5px;
      background-color: rgba(128, 0, 128, 0.342);
      border-radius: 5px;
      align-self: flex-end;
    }
  }

  /* LEFT */
  &-title {
    font-weight: bold;
    color: var(--font-color-2);
    font-size: 1.4rem;
  }

  &-counter {
    font-size: 2.8rem;
    font-weight: 300;
  }

  &-link {
    font-size: 1.2rem;
    border-bottom: 1px solid var(--font-color);
    width: fit-content;
  }
  /* LEFT */

  /* RIGHT */
  &-percent {
    display: flex;
    align-items: center;
    font-size: 1.5rem;

    &__positive {
      color: var(--color-green);
    }

    &__negative {
      color: var(--color-red);
    }
  }
  /* RIGHT */
}
