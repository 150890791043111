.form__forget-password {
  display: flex;
  align-items: center;
  justify-content: center;

  &__group {
    width: 100%;
    border: 1px solid var(--color-border);
    background-color: #fff;
    width: 60rem;
    border-radius: 6px;
    overflow: hidden;
  }

  &__header {
    font-size: 2.4rem;
    border-bottom: 1px solid var(--color-border);
    padding: 2.2rem 3rem 1.4rem 3rem;
    margin-bottom: 1.4rem;
  }

  &__body {
    padding: 0 3rem 3rem 3rem;
  }

  &__btn {
    font-size: 1.6rem;
  }

  &__text {
    font-size: 1.6rem;
  }

  &__link {
    display: inline-block;
    margin-left: 0.6rem;
    color: var(--color-primary);
    font-weight: 500;
  }
}
