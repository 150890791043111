@import "../../../shared/sass/abstracts/mixins";

.ManagementStudent__icon {
  @include circle(1.8em, 1.8rem);
  padding: 1.4rem;
  min-width: unset;

  &:hover {
    background-color: var(--color-primary-2);
    color: #fff;
  }

  &:first-child {
    margin-right: 1.2rem;
  }
}
