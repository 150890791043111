.form-input {
  &__group {
    width: 100%;

    position: relative;

    &:not(:last-child) {
      margin: 2rem 0 1.6rem 0;
    }
  }

  &__label {
    display: block;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0 0 0.7rem 0.7rem;
    transition: all 0.2s ease-in;
  }

  &__input {
    min-height: 5rem;
    width: 100%;
    border: 1px solid var(--color-grey-light-3);
    border-radius: 5px;
    padding: 1rem 1.4rem;
    font-size: 1.5rem;
    outline: none;
    transition: all 0.2s ease;

    &:focus,
    &:active {
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
      border: 1px solid var(--color-green);
    }

    &:focus:invalid,
    &:invalid,
    &--error {
      border: 1px solid var(--color-red) !important;
    }

    // NO BORDER
    &--no-border {
      border: none;
      &:focus,
      &:active {
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
        border-bottom: 3px solid var(--color-green);
      }

      &:focus:invalid,
      &:invalid,
      &--error {
        border-bottom: 3px solid var(--color-red);
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }

  // FUNCTIONALITY PASSWORD
  &__hidden-password {
    position: absolute;
    right: 0.8rem;
    font-size: 1.8rem;
    color: var(--color-grey-dark-2);
    padding: 1.6rem;
    cursor: pointer;
    z-index: 11;

    &:hover {
      opacity: 0.8;
    }
  }

  &__text {
    font-size: 1.5rem;
    margin: 0 0 0 1.2rem;
    color: var(--font-color-2);
  }
}

.form-input--loading {
  position: absolute;
  right: 7%;
  font-size: 2rem;
  transform: translate(-50%, -50%);

  animation: lds-dual-ring 1.2s linear infinite;
}

/* MUI */
.MuiTypography-root {
  font-size: 1.4rem !important;
}
