.btn {
  font-size: 1.4rem;
  padding: 1.2rem 2rem;
  border-radius: 3px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  border: none;

  min-width: 8.5rem;
  cursor: pointer;

  display: flex;
  align-items: center;

  justify-content: center;
  &--primary {
    background-color: var(--color-primary);
    color: #fff;

    &:hover {
      background-color: var(--color-primary-2);
    }
  }

  &--sub-primary {
    background-color: var(--color-primary-2);
    color: #fff;

    &:hover {
      background-color: var(--color-primary);
    }
  }

  &--loading {
    margin-left: 1.2rem;
    z-index: 99;

    &::after {
      content: " ";
      display: block;
      width: 2.6rem;
      height: 2.6rem;
      margin: 1px;
      border-radius: 50%;
      border: 4px solid currentColor;
      border-color: currentColor currentColor currentColor transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }

  &--borderOnly {
    background-color: transparent;
    color: var(--color-primary-2);
    border: 1px solid var(--color-border);

    &:hover {
      background-color: var(--color-primary-2);
      color: #fff;
    }
  }

  &--disabled {
    user-select: none;
    cursor: default;
    background-color: var(--color-grey-dark-2) !important;
  }

  &--fullWidth {
    width: 100%;
  }
}
