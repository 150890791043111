.admin-featured {
  flex: 2;
  padding: 10px;
  box-shadow: var(--shadow-light-2);
  height: max-content;

  &__title {
    font-weight: 500;
    color: var(--font-color-2);
    font-size: 1.8rem;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--font-color-2);
    font-size: 1.6rem;
    font-weight: 500;
  }

  &__icon {
    font-size: 2rem !important;
  }

  &__bottom {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }

  &__amount {
    font-size: 3rem;
  }

  &__chart {
    width: 10rem;
    height: 10rem;
  }
}

.admin-featured__summary {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > * {
    font-size: 1.6rem;
  }

  &-item {
    text-align: center;
  }

  &-title {
    color: var(--font-color-2);
  }

  &-result {
    display: flex;
    align-items: center;
    margin-top: 10px;

    &.positive {
      color: var(--color-green);
    }

    &.negative {
      color: var(--color-red);
    }
  }
}
