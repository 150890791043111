@import "../../../shared/sass/abstracts/mixins";

.post-ad__header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-grey-light-3);

  &--step {
    width: 33.33333%;
    padding: 2rem 3rem;
    display: flex;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: all 0.2s ease-in-out;

    &.active {
      background-color: var(--color-primary);
      color: #fff;

      p {
        color: #fff;
      }
    }
  }

  &-number {
    @include circle(5rem, 5rem, var(--color-grey-light-3), #fff);
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    color: var(--color-primary);
  }

  &-sub-title {
    margin-left: 1.2rem;

    h4 {
      font-size: 1.8rem;
    }

    p {
      font-size: 1.4rem;
      color: var(--color-grey-dark-2);
    }
  }
}
