.post-ad__title {
  text-align: center;
  font-size: 2.6rem;
  width: 70%;
  margin-bottom: 24px;
}

.post-ad__link {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
