@import "../../sass/abstracts/mixins";

.sidebar-chat {
  border-right: 1px solid var(--color-border-dark);
  overflow-y: auto;
  min-height: 100vh;

  &::-webkit-scrollbar {
    width: 0;
    background-color: #fff;
  }

  /* HEADER */
  &__header {
    padding: 1.5rem 2rem;
    font-weight: 600;
    font-size: 2rem;
    border-bottom: 1px solid var(--color-border-dark);
  }

  &__quantity {
    font-size: 1.6rem;
    border: 1px solid var(--color-border-dark);
    border-radius: 5px;
    padding: 0.5rem 1rem;
    background-color: var(--color-grey-light-1);
    margin-left: 2.4rem;
  }
  /* HEADER */

  /* SEARCH */
  &__search {
    padding: 1.5rem 2rem;
    background-color: #fff;
  }

  &__search-group {
    display: flex;
    align-items: center;
    border: 1px solid var(--color-border-dark);
    background-color: var(--color-grey-light-1);
    border-radius: 5px;
  }

  &__input {
    height: 4.3rem;
    font-size: 1.4rem;
    padding: 1.5rem 0.6rem 1.5rem 1.5rem;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
  }

  &__search-icon {
    padding: 1rem;
    font-size: 1.6rem;
    color: var(--color-grey-dark-2);
    z-index: 1;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
  /* SEARCH */
}
