.popper-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: fit-content;
  border-radius: 7px;
  background: rgb(255, 255, 255);
  box-shadow: var(--shadow-dark-slightly);
  transition: all 0.3s linear;
  z-index: 99;
}
