@import "../../sass/main.scss";

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

ul {
  margin: 0;
}

a {
  text-decoration: none;
}

.section-heading {
  text-align: center;

  &.primary {
    h2,
    p {
      color: #fff;
    }
  }

  &.tertiary {
    h2 {
      color: #000;
    }

    p {
      color: var(--color-grey-dark-2);
    }
  }

  h2 {
    color: #fff;
    font-weight: 700;
    font-size: 4.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 2.5rem;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      display: block;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--color-primary);
      height: 0.4rem;
      width: 6rem;
    }
  }

  p {
    font-weight: 400;
    font-size: 1.6rem;
    display: inline-block;
    width: 35%;
  }
}

/* Animation */
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.main-action__admin-icon {
  @include circle(1.8em, 1.8rem);
  padding: 1.4rem;
  min-width: unset;

  &:hover {
    background-color: var(--color-primary-2);
    color: #fff;
  }

  &:first-child {
    margin-right: 1.2rem;
  }
}

.css-12u49z-MuiDataGrid-root,
.css-204u17-MuiDataGrid-main {
  font-size: 1.5rem !important;
}
