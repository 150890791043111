@import "../../sass/abstracts/mixins";

.image {
  box-shadow: var(--shadow-light);
  border: 1px solid var(--color-border-dark);
  border-radius: 50%;
  object-fit: cover;

  &.circle {
    @include circle(5rem, 5rem, var(--color-border-dark), #fff);
  }
}
