.auth-form {
  &__forget {
    font-size: 1.5rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--font-color-2);
    margin: 1.6rem 0 1.8rem 0;

    &-checkbox {
      border: 1px solid var(--color-grey-light-2);
    }

    &-label {
      margin-left: 0.8rem;
      display: block;
    }

    &-link {
      color: var(--font-color-2);
      transition: color 0.2s ease-in;

      &:hover {
        text-decoration: underline;
        color: var(--color-primary);
      }
    }
  }
}

.outer__link {
  a {
    color: var(--color-primary-2);
    font-weight: 500;

    &:hover {
      color: var(--color-primary);
    }
  }
}
