@import "../../sass/abstracts/mixins";

.card-product {
  background-color: var(--color-grey-light-2);
  border-radius: 5px;
  border: 1px solid var(--color-border-dark);
  overflow: hidden;
  box-shadow: var(--shadow-light);
  cursor: pointer;

  &:hover {
    box-shadow: var(--shadow-dark-slightly);
  }

  &__header {
    height: 210px;
    overflow: hidden;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover &__image {
    transform: scale(1.05);
  }

  &__body {
    padding: 2rem;
    transition: background-color 0.3s ease-in;
  }

  &:hover &__body {
    background-color: #f7f7f770;
  }

  &__category {
    font-size: 1.3rem;
    color: var(--color-grey-dark-2);
    font-weight: 500;
    border-bottom: 1px solid #eee;
    padding-bottom: 1.2rem;
    margin-bottom: 0.6rem;
  }

  &__name {
    font-size: 1.8rem;
    font-weight: 600;
    color: #000;
    transition: color 0.3s ease;

    &:hover {
      color: var(--color-primary);
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.4rem;
  }

  &__price {
    font-size: 1.8rem;
    color: var(--color-primary);
    font-weight: 600;
  }

  &__heart {
    @include circle(1.5rem, 1.5rem, var(--color-grey-light-3), #fff);
    padding: 0.8rem;

    &:hover {
      background-color: var(--color-primary);
      color: #fff;
    }
  }
}
