.sidebar-user {
  border: 1px solid var(--color-border);
  border-radius: 5px;
  background-color: #fff;

  &__header {
    padding: 3rem;
    display: flex;
    border-bottom: 1px solid var(--color-border);
  }

  &__avatar {
    height: 5rem;
    width: 5rem;
    border-radius: 5px;
    object-fit: cover;
  }

  &__info {
    margin-left: 1.2rem;
  }

  &__name {
    font-size: 1.5rem;
    font-weight: 700;
  }

  &__email {
    font-size: 1.3rem;
    color: var(--font-color-2);
    font-weight: 500;
  }

  // LIST
  &__list {
    list-style: none;
    margin: 3rem 0;
  }

  &__item {
    position: relative;
    display: block;
    cursor: pointer;

    // &::before {
    //   position: absolute;
    //   content: "";
    //   display: block;
    //   top: 0;
    //   left: 0;
    //   height: 0;
    //   width: 0.4rem;
    //   background-color: var(--color-primary);
    //   border-radius: 2px;
    //   transition: height 0.2s ease-in-out;
    // }
  }

  &__item:hover &__link {
    color: var(--color-primary);
  }

  &__item:hover &__link::before {
    height: 100%;
  }

  &__link {
    font-size: 1.5rem;
    color: var(--font-color-2);
    padding: 1rem 4rem;
    font-weight: 500;
    transition: color 0.2s linear;
    display: block;

    &::before {
      position: absolute;
      content: "";
      display: block;
      top: 0;
      left: 0;
      height: 0;
      width: 0.4rem;
      background-color: var(--color-primary);
      border-radius: 2px;
      transition: height 0.2s ease-in-out;
    }

    &.active {
      color: var(--color-primary);
    }

    &.active::before {
      height: 100%;
    }
  }

  &__icon {
    margin-right: 1.4rem;
  }
}
