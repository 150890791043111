.bread-crumbs {
  background-color: var(--color-primary-2);
  color: #fff;
  padding: 4.5rem 0;

  &__group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__header {
    font-size: 2.2rem;
  }

  &__list {
    display: flex;
    align-items: center;
    list-style: none;
  }

  &__item {
    span,
    a {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  &__icon {
    font-size: 1.5rem;
    margin: 0 1.6rem;
    transform: translateY(1px);
  }
}
