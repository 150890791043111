@import "../../sass/abstracts/mixins";

.card-chat {
  display: flex;
  padding: 1.6rem 2.4rem;
  transition: all 0.2s ease-in;
  cursor: pointer;

  &:hover {
    background-color: var(--color-grey-light-1);
  }
  
  &__detail {
    margin-left: 1.2rem;
  }

  &__avatar {
    position: relative;

    &--active {
      // Active
      &::before {
        position: absolute;
        content: "";
        height: 1rem;
        width: 1rem;
        background-color: var(--color-green);
        border-radius: 50%;
        box-shadow: var(--shadow-light);
        bottom: 0.7rem;
        right: 0.2rem;
      }
    }
  }

  &__img {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    box-shadow: var(--shadow-light);
    object-fit: cover;
  }

  &__name {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 0.6rem;
  }

  &__message {
    @include breakWord(20rem);
    font-size: 1.3rem;
    font-weight: 400;
    color: var(--color-grey-dark-2);
  }
}
