@import "../../../shared/sass/abstracts/mixins";

.user-location__list-item {
  &:not(:last-child) {
    padding-bottom: 2.4rem;
    margin-top: 2.4rem;
    border-bottom: 1px solid var(--color-border);
  }
}

.user-location__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.user-location__details {
  h4 {
    font-size: 1.4rem;
    font-weight: 400;
    margin: 0;
  }

  span {
    font-size: 1.4rem;
    margin-left: 0.8rem;
    color: var(--font-color-2);
  }
}

.user-location__action {
  &-group {
    display: flex;
    flex-direction: column;
  }

  &-btn {
    padding: 0.8rem;
    font-size: 1.3rem;
  }

  &-icon:first-child {
    margin-right: 1.2rem;
  }

  &-icon {
    @include circle(1.5rem, 1.5rem);
    font-size: 1.8rem;
    padding: 0.8rem;

    &:hover {
      background-color: var(--color-primary-2);
      color: #fff;
    }
  }
}
