.FileUpload {
  display: flex;
  flex-direction: column;

  &__name {
    font-size: 1.4rem;
    margin: 1.2rem 0 0 0;
  }

  &__btn {
    width: fit-content;
    padding: 0.4rem;
  }
}

.FileUpload__icon {
  font-size: 1.6rem;
  margin-right: 0.8rem;
}
