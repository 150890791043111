:root {
  --height-thead: 40px;
}

.Scholarship__Table {
  padding: 1.6rem;

  &__Header {
    display: flex;
    align-items: center;
  }

  .table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid var(--color-border);
    background-color: #fff;
    border-radius: 10px;
  }

  th {
    &:first-child {
      width: 20%;
    }
  }
}

.Scholarship__TableHeader {
  height: calc(var(--height-thead) + 2px);
  line-height: var(--height-thead);
}

.Scholarship__Icon {
  font-size: 2.2rem !important;

  &Green {
    color: var(--color-green-dark);
  }

  &Red {
    color: var(--color-red);
  }
}

.MuiSvgIcon-root {
  font-size: 2.6rem !important;
}

.ScholarShipBudget {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin: 0;

  &__text {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    &:last-child {
      margin-left: 1.4rem;
    }
  }

  span {
    font-weight: 700;
    margin-right: 0.8rem;
  }
}
