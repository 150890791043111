.PaginationWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  list-style: none;
}

.PaginationPageItem {
  margin: 0 6px;
  color: var(--color-font);
  border: 1px solid var(--color-border);
  transition: all 0.2s;
  padding: 8px 14px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.6rem;

  &:hover {
    color: #fff;
    background-color: var(--color-primary);
  }
}

.PaginationPageItem__active {
  color: #fff;
  background-color: var(--color-primary);
}
