.seller-form__text {
  font-size: 1.5rem;
  color: var(--color-grey-dark-2);
  font-style: italic;
  margin: 1.6rem 0;
}

.seller-form__btn {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.seller-notification {
  &__title {
    font-size: 2.3rem;
    font-weight: 500;
    text-align: center;
  }

  &__text {
    font-size: 1.4rem;
    margin-top: 1.2rem;
    text-align: center;
  }
}

.form-seller__profile-text {
  font-size: 1.5rem;
  font-weight: 500;
  font-style: italic;
  color: var(--font-color-1);
  margin: 0.6rem 0 1.2rem 0;
}