@import "../../../shared/sass/abstracts/mixins";

.MainToolbar {
  border: 1px solid var(--color-border);
  border-radius: 5px;
  margin: 2rem 2rem 1.2rem 2rem;

  &__group {
    padding: 1.2rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

